$(document).ready(function(){
	$('.search-menu a').attr('data-effect','mfp-zoom-in');
	if($('.tax-product_category').is(':visible')){
	    $('html, body').animate({
	        scrollTop: $('#product-section-one').offset().top - 70
	    }, 1000);
	}
	$(window).load(function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		$('.equalize-custom-wrapper').matchHeight();

		$('.custom-slick-slider').on('init', function(e, slick) {
	        var $firstAnimatingElements = $('div.slick-slide:first-child').find('[data-animation]');
	        doAnimations($firstAnimatingElements);   
        }); 

        $('.custom-slick-slider').on('beforeChange', function(e, slick, currentSlide, nextSlide) {
			var $animatingElements = $('div.slick-slide[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
			doAnimations($animatingElements);    
		});

		$('.div-wrapper').css({
			'min-height': $pageHeight - ( $navHeight + $footerHeight ),
		});

		// INITIATIONS
		//AOS.init();
		$('.product-btn').magnificPopup({
		  delegate: 'a',
		  removalDelay: 500, //delay removal by X to allow out-animation
		  callbacks: {
		    beforeOpen: function() {
		       this.st.mainClass = this.st.el.attr('data-effect');
		    }
		  },
		  midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
		});


		$(document).on('click', '.post-btn', function () {
			var $getcolor = $(this).data('getcolor');
			var $DATA = $(this).data('getid');
			// alert($DATA);
			$.ajax({
				type : "post",
				dataType : "json",
				url : $('.ajaxlocation').data('ajaxlocation'),
				data : {
					action: "get_product_details", 
					post_id : $DATA,
					post_color : $getcolor,
				},
				beforeSend: function () {
					$('.loader-show').show();
					$('.container-search').hide();
					$('.mfp-close').hide();
				},
				success: function(data) {
					setTimeout(function(){
						$('.loader-show').hide();
					}, 1000);
					if( data.response == 1 ) {
						setTimeout(function(){
							$('.mfp-close').show();
							$('.container-search').show();
							$('.container-search').html(data.html);
						}, 2000);
					} else {
						$('.container-search').html('<div class="message-error">No Data Found.</div>');
					}
				}
			});
		});

		// CUSTOM FUNCTIONS
		mobileLayout();
		cf7formsubmit();

		$('.js-scroll-trigger i.fa-angle-down, .js-scroll-trigger a[href*="#"]:not([href="#"])').click(function() {
		    if ( location.hostname == this.hostname) {
		      var target = $(this.hash);
		      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
		      if (target.length) {
		        $('html, body').animate({
		          scrollTop: (target.offset().top - 60)
		        }, 1000, "easeInOutExpo");
		        return false;
		      }
		    }
		});

		$('.loader-overlay').fadeOut(50);
		
		var swiper = new Swiper('.swiper-container', {
	        direction: 'vertical',
	        slidesPerView: 1,
	        spaceBetween: 0,
	        mousewheel: true,
	        calculateHeight : true,
	        pagination: {
	          el: '.swiper-pagination',
	          clickable: true,
	        },
			speed: 800,
			navigation: {
		      nextEl: '.swiper-button-next',
		      // prevEl: '.swiper-button-prev',
		    }
	    });

	    swiper.on('slideChange', function () {
	    	var $count = swiper.activeIndex;

            if (swiper.activeIndex == 0) {
            	$('.swiper-button-next').removeClass('color-gray');
            } else {
            	/*if (!!navigator.userAgent.match(/Trident\/7\./)){
					//do stuff for IE.
					// alert();
				} else {
				}*/
				if (document.documentMode || /Edge/.test(navigator.userAgent)) {
				    // alert('Hello Microsoft User!');
				} else {
			    	var $animatingElements = $('div.swiper-slide[data-slick-index="' + $count + '"]').find('[data-animation]');
					doAnimations($animatingElements);
				}
		  		$('.swiper-button-next').addClass('color-gray');
            }
		});

		// BACK TO TOP
		$('.back-to-top').hide(); // HIDE ON FIRST LOAD
		$(document).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('.back-to-top').fadeIn();
			} else {
				$('.back-to-top').fadeOut();
			}
		});
		$('.back-to-top a').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});

	});

	$(window).on('resize', function(){
		$('.equalize-custom-wrapper').matchHeight();
	});

	$('.custom-slick-slider').slick({
  	  autoplaySpeed: 2000,
	  autoplay: true,
	  dots: true,
	  infinite: true,
	  speed: 500,
	  fade: true,
	  arrows: true,
	  cssEase: 'linear'
	});

	function doAnimations(elements) {
        var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        elements.each(function() {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
                'animation-delay': $animationDelay,
                '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function() {
                $this.removeClass($animationType);
            });
        });
    }

	$('.partners-gallery').slick({
	  infinite: true,
	  slidesToShow: 3,
	  slidesToScroll: 1,
  	  autoplaySpeed: 2000,
	  autoplay: true,
	  speed: 1000,
	  // useTransform: true,
      cssEase: 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
	  responsive: [
	    {
	      breakpoint: 1024,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 1,
	        infinite: true,
	        dots: true
	      }
	    },
	    {
	      breakpoint: 600,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 1
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 1
	      }
	    }
	  ]
	});
});


// FUNCTION LISTS
/*
* Method smooth scrolls to given anchor point
*/
function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}
function mobileLayout(){
	// MOBILE MENU LAYOUT
	$('.sidepanel .menu > .menu-item-has-children').addClass('dropdown row-size');
	$('.sidepanel .menu > .menu-item-has-children > a').each(function(){
		var $curr = $(this);
		$curr.addClass('column-top nav-title');
		$('<span class="fa fa-plus dropdown-toggle nav-control column-top" data-toggle="dropdown" style="min-height: '+ $curr.outerHeight() +'px;"></span>').insertAfter( $curr );			
	});
	$('.sidepanel .menu > .menu-item-has-children > .sub-menu').addClass('dropdown-menu');
	// MOBILE MENU
	if(!$('.sidepanel').hasClass('sidepanel-out')){
		$('.close-sidemenu').hide();
	}
	$('.mobile-menu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out" );
		$('.get-overlay').toggleClass("overlay");
		$(this).toggleClass('toggle-mobile-menu');
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.get-overlay').click(function(){
		$(this).toggleClass("overlay");
		$('.sidepanel').toggleClass("sidepanel-out" );
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.close-sidemenu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out");
		$(this).hide();
	});
	$('.sidepanel li a').click(function(){
		$(this).find('.fa-plus').toggleClass('fa-minus');
	});


	$(document).on('click', '.mobile-menu, .get-overlay', function(){
		$('.menu-button').toggleClass('active');
	});
}

function cf7formsubmit(){
	var sitelink = $('.usd').data('usdacct');
	document.addEventListener( 'wpcf7mailsent', function( event ) {
	  location = sitelink + '/thank-you/';
	}, false );
}

function locations($country, $state, $city){
	// Sample Field
	// "select.csCountryOption", "select.csStateOption", "select.csCityOption"
	filter_country_state_city($country, $state, $city);
}

function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase();
}

function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

(function(factory) { // eslint-disable-line no-extra-semi
    'use strict';
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery'], factory);
    } else if (typeof module !== 'undefined' && module.exports) {
        // CommonJS
        module.exports = factory(require('jquery'));
    } else {
        // Global
        factory(jQuery);
    }
})(function($) {
    /*
    *  internal
    */

    var _previousResizeWidth = -1,
        _updateTimeout = -1;

    /*
    *  _parse
    *  value parse utility function
    */

    var _parse = function(value) {
        // parse value and convert NaN to 0
        return parseFloat(value) || 0;
    };

    /*
    *  _rows
    *  utility function returns array of jQuery selections representing each row
    *  (as displayed after float wrapping applied by browser)
    */

    var _rows = function(elements) {
        var tolerance = 1,
            $elements = $(elements),
            lastTop = null,
            rows = [];

        // group elements by their top position
        $elements.each(function(){
            var $that = $(this),
                top = $that.offset().top - _parse($that.css('margin-top')),
                lastRow = rows.length > 0 ? rows[rows.length - 1] : null;

            if (lastRow === null) {
                // first item on the row, so just push it
                rows.push($that);
            } else {
                // if the row top is the same, add to the row group
                if (Math.floor(Math.abs(lastTop - top)) <= tolerance) {
                    rows[rows.length - 1] = lastRow.add($that);
                } else {
                    // otherwise start a new row group
                    rows.push($that);
                }
            }

            // keep track of the last row top
            lastTop = top;
        });

        return rows;
    };

    /*
    *  _parseOptions
    *  handle plugin options
    */

    var _parseOptions = function(options) {
        var opts = {
            byRow: true,
            property: 'height',
            target: null,
            remove: false
        };

        if (typeof options === 'object') {
            return $.extend(opts, options);
        }

        if (typeof options === 'boolean') {
            opts.byRow = options;
        } else if (options === 'remove') {
            opts.remove = true;
        }

        return opts;
    };

    /*
    *  matchHeight
    *  plugin definition
    */

    var matchHeight = $.fn.matchHeight = function(options) {
        var opts = _parseOptions(options);

        // handle remove
        if (opts.remove) {
            var that = this;

            // remove fixed height from all selected elements
            this.css(opts.property, '');

            // remove selected elements from all groups
            $.each(matchHeight._groups, function(key, group) {
                group.elements = group.elements.not(that);
            });

            // TODO: cleanup empty groups

            return this;
        }

        if (this.length <= 1 && !opts.target) {
            return this;
        }

        // keep track of this group so we can re-apply later on load and resize events
        matchHeight._groups.push({
            elements: this,
            options: opts
        });

        // match each element's height to the tallest element in the selection
        matchHeight._apply(this, opts);

        return this;
    };

    /*
    *  plugin global options
    */

    matchHeight.version = 'master';
    matchHeight._groups = [];
    matchHeight._throttle = 80;
    matchHeight._maintainScroll = false;
    matchHeight._beforeUpdate = null;
    matchHeight._afterUpdate = null;
    matchHeight._rows = _rows;
    matchHeight._parse = _parse;
    matchHeight._parseOptions = _parseOptions;

    /*
    *  matchHeight._apply
    *  apply matchHeight to given elements
    */

    matchHeight._apply = function(elements, options) {
        var opts = _parseOptions(options),
            $elements = $(elements),
            rows = [$elements];

        // take note of scroll position
        var scrollTop = $(window).scrollTop(),
            htmlHeight = $('html').outerHeight(true);

        // get hidden parents
        var $hiddenParents = $elements.parents().filter(':hidden');

        // cache the original inline style
        $hiddenParents.each(function() {
            var $that = $(this);
            $that.data('style-cache', $that.attr('style'));
        });

        // temporarily must force hidden parents visible
        $hiddenParents.css('display', 'block');

        // get rows if using byRow, otherwise assume one row
        if (opts.byRow && !opts.target) {

            // must first force an arbitrary equal height so floating elements break evenly
            $elements.each(function() {
                var $that = $(this),
                    display = $that.css('display');

                // temporarily force a usable display value
                if (display !== 'inline-block' && display !== 'flex' && display !== 'inline-flex') {
                    display = 'block';
                }

                // cache the original inline style
                $that.data('style-cache', $that.attr('style'));

                $that.css({
                    'display': display,
                    'padding-top': '0',
                    'padding-bottom': '0',
                    'margin-top': '0',
                    'margin-bottom': '0',
                    'border-top-width': '0',
                    'border-bottom-width': '0',
                    'height': '100px',
                    'overflow': 'hidden'
                });
            });

            // get the array of rows (based on element top position)
            rows = _rows($elements);

            // revert original inline styles
            $elements.each(function() {
                var $that = $(this);
                $that.attr('style', $that.data('style-cache') || '');
            });
        }

        $.each(rows, function(key, row) {
            var $row = $(row),
                targetHeight = 0;

            if (!opts.target) {
                // skip apply to rows with only one item
                if (opts.byRow && $row.length <= 1) {
                    $row.css(opts.property, '');
                    return;
                }

                // iterate the row and find the max height
                $row.each(function(){
                    var $that = $(this),
                        style = $that.attr('style'),
                        display = $that.css('display');

                    // temporarily force a usable display value
                    if (display !== 'inline-block' && display !== 'flex' && display !== 'inline-flex') {
                        display = 'block';
                    }

                    // ensure we get the correct actual height (and not a previously set height value)
                    var css = { 'display': display };
                    css[opts.property] = '';
                    $that.css(css);

                    // find the max height (including padding, but not margin)
                    if ($that.outerHeight(false) > targetHeight) {
                        targetHeight = $that.outerHeight(false);
                    }

                    // revert styles
                    if (style) {
                        $that.attr('style', style);
                    } else {
                        $that.css('display', '');
                    }
                });
            } else {
                // if target set, use the height of the target element
                targetHeight = opts.target.outerHeight(false);
            }

            // iterate the row and apply the height to all elements
            $row.each(function(){
                var $that = $(this),
                    verticalPadding = 0;

                // don't apply to a target
                if (opts.target && $that.is(opts.target)) {
                    return;
                }

                // handle padding and border correctly (required when not using border-box)
                if ($that.css('box-sizing') !== 'border-box') {
                    verticalPadding += _parse($that.css('border-top-width')) + _parse($that.css('border-bottom-width'));
                    verticalPadding += _parse($that.css('padding-top')) + _parse($that.css('padding-bottom'));
                }

                // set the height (accounting for padding and border)
                $that.css(opts.property, (targetHeight - verticalPadding) + 'px');
            });
        });

        // revert hidden parents
        $hiddenParents.each(function() {
            var $that = $(this);
            $that.attr('style', $that.data('style-cache') || null);
        });

        // restore scroll position if enabled
        if (matchHeight._maintainScroll) {
            $(window).scrollTop((scrollTop / htmlHeight) * $('html').outerHeight(true));
        }

        return this;
    };

    /*
    *  matchHeight._applyDataApi
    *  applies matchHeight to all elements with a data-match-height attribute
    */

    matchHeight._applyDataApi = function() {
        var groups = {};

        // generate groups by their groupId set by elements using data-match-height
        $('[data-match-height], [data-mh]').each(function() {
            var $this = $(this),
                groupId = $this.attr('data-mh') || $this.attr('data-match-height');

            if (groupId in groups) {
                groups[groupId] = groups[groupId].add($this);
            } else {
                groups[groupId] = $this;
            }
        });

        // apply matchHeight to each group
        $.each(groups, function() {
            this.matchHeight(true);
        });
    };

    /*
    *  matchHeight._update
    *  updates matchHeight on all current groups with their correct options
    */

    var _update = function(event) {
        if (matchHeight._beforeUpdate) {
            matchHeight._beforeUpdate(event, matchHeight._groups);
        }

        $.each(matchHeight._groups, function() {
            matchHeight._apply(this.elements, this.options);
        });

        if (matchHeight._afterUpdate) {
            matchHeight._afterUpdate(event, matchHeight._groups);
        }
    };

    matchHeight._update = function(throttle, event) {
        // prevent update if fired from a resize event
        // where the viewport width hasn't actually changed
        // fixes an event looping bug in IE8
        if (event && event.type === 'resize') {
            var windowWidth = $(window).width();
            if (windowWidth === _previousResizeWidth) {
                return;
            }
            _previousResizeWidth = windowWidth;
        }

        // throttle updates
        if (!throttle) {
            _update(event);
        } else if (_updateTimeout === -1) {
            _updateTimeout = setTimeout(function() {
                _update(event);
                _updateTimeout = -1;
            }, matchHeight._throttle);
        }
    };

    /*
    *  bind events
    */

    // apply on DOM ready event
    $(matchHeight._applyDataApi);

    // use on or bind where supported
    var on = $.fn.on ? 'on' : 'bind';

    // update heights on load and resize events
    $(window)[on]('load', function(event) {
        matchHeight._update(false, event);
    });

    // throttled update heights on resize events
    $(window)[on]('resize orientationchange', function(event) {
        matchHeight._update(true, event);
    });

});

